/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { 
  RiArrowRightSLine, 
  RiPhoneLine, 
  RiMailLine, 
  RiCalendarLine, 
  RiUserSettingsLine, 
  RiBarChartBoxLine, 
  RiTeamLine,
  RiCheckLine,
  RiToolsLine,
  RiPieChartLine,
  RiBookOpenLine,
  RiFileListLine
} from "react-icons/ri"
import { RiFacebookBoxFill, RiTwitterFill, RiLinkedinBoxFill, RiYoutubeFill, RiInstagramFill, RiRssFill, RiGithubFill, RiTelegramFill, RiPinterestFill, RiSnapchatFill, RiSkypeFill,RiDribbbleFill, RiMediumFill, RiBehanceFill} from "react-icons/ri";
import { FaWordpress, FaVk} from "react-icons/fa";

import Layout from "../components/layout"
import BlogListHome from "../components/blog-list-home"
import SEO from "../components/seo"
import Icons from "../util/socialmedia.json"

export const pageQuery = graphql`
  query HomeQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        tagline
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
          }
        }
        cta {
          ctaText
          ctaLink
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "blog-post" } } }
      limit: 3
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { markdownRemark, posts } = data
  const { frontmatter, html } = markdownRemark
  const Image = frontmatter.featuredImage
  ? frontmatter.featuredImage.childImageSharp.gatsbyImageData
  : ""

  const features = [
    {
      icon: <RiUserSettingsLine />,
      title: "Performance Reviews",
      description: "Streamline employee evaluations with customizable templates and automated workflows."
    },
    {
      icon: <RiBarChartBoxLine />,
      title: "HR Analytics",
      description: "Make data-driven decisions with powerful insights and reporting tools."
    },
    {
      icon: <RiTeamLine />,
      title: "Team Management",
      description: "Efficiently manage teams, track goals, and improve collaboration."
    }
  ]

  const hrTechServices = [
    {
      icon: <RiPieChartLine />,
      title: "HRIS Implementation",
      description: "End-to-end implementation of HR Information Systems with data migration and integration."
    },
    {
      icon: <RiBarChartBoxLine />,
      title: "HR Tech Strategy",
      description: "Strategic technology roadmapping aligned with your business goals and workforce needs."
    },
    {
      icon: <RiTeamLine />,
      title: "System Optimization",
      description: "Audit and enhancement of existing HR systems to maximize ROI and user adoption."
    },
    {
      icon: <RiUserSettingsLine />,
      title: "Workplace Analytics",
      description: "Custom analytics solutions to derive actionable insights from your workforce data."
    }
  ]

  const resources = [
    {
      icon: <RiToolsLine />,
      title: "HR Tools",
      description: "Interactive calculators and tools to optimize your HR processes and decision-making",
      link: "/tools"
    },
    {
      icon: <RiBookOpenLine />,
      title: "HR Guides",
      description: "Comprehensive guides on best practices for HR management and strategy",
      link: "/guides"
    },
    {
      icon: <RiFileListLine />,
      title: "HR Policies",
      description: "Customizable policy templates and compliance resources for your organization",
      link: "/policies"
    }
  ]

  // Create social icons with proper keys
  const sIcons = Icons.socialIcons.map((icons) => {
    const iconKey = `social-icon-${icons.icon}`;
    return(
      <div key={iconKey}>
        { icons.icon === "facebook" ? (<Link to={icons.url} target="_blank" rel="noopener noreferrer"><RiFacebookBoxFill/></Link>) : "" }
        { icons.icon === "twitter" ? (<Link to={icons.url} target="_blank" rel="noopener noreferrer"><RiTwitterFill/></Link>) : "" }
        { icons.icon === "linkedin" ? (<Link to={icons.url} target="_blank" rel="noopener noreferrer"><RiLinkedinBoxFill/></Link>) : "" }
        { icons.icon === "youtube" ? (<Link to={icons.url} target="_blank" rel="noopener noreferrer"><RiYoutubeFill/></Link>) : "" }
        { icons.icon === "instagram" ? (<Link to={icons.url} target="_blank" rel="noopener noreferrer"><RiInstagramFill/></Link>) : "" }
        { icons.icon === "rss" ? (<Link to={icons.url} target="_blank" rel="noopener noreferrer"><RiRssFill/></Link>) : "" }
        { icons.icon === "github" ? (<Link to={icons.url} target="_blank" rel="noopener noreferrer"><RiGithubFill/></Link>) : "" }
        { icons.icon === "telegram" ? (<Link to={icons.url} target="_blank" rel="noopener noreferrer"><RiTelegramFill/></Link>) : "" }
        { icons.icon === "pinterest" ? (<Link to={icons.url} target="_blank" rel="noopener noreferrer"><RiPinterestFill/></Link>) : "" }
        { icons.icon === "snapchat" ? (<Link to={icons.url} target="_blank" rel="noopener noreferrer"><RiSnapchatFill/></Link>) : "" }
        { icons.icon === "skype" ? (<Link to={icons.url} target="_blank" rel="noopener noreferrer"><RiSkypeFill/></Link>) : "" }
        { icons.icon === "wordpress" ? (<Link to={icons.url} target="_blank" rel="noopener noreferrer"><FaWordpress/></Link>) : "" }
        { icons.icon === "dribbble" ? (<Link to={icons.url} target="_blank" rel="noopener noreferrer"><RiDribbbleFill/></Link>) : "" }
        { icons.icon === "medium" ? (<Link to={icons.url} target="_blank" rel="noopener noreferrer"><RiMediumFill/></Link>) : "" }
        { icons.icon === "behance" ? (<Link to={icons.url} target="_blank" rel="noopener noreferrer"><RiBehanceFill/></Link>) : "" }
        { icons.icon === "vk" ? (<Link to={icons.url} target="_blank" rel="noopener noreferrer"><FaVk/></Link>) : "" }
      </div>
    )
  });
  
  // Content section component for HTML content
  const ContentSection = () => {
    return (
      <section className="content-section">
        <div 
          // Using a sanitized approach to render content
          // This avoids security risks of dangerouslySetInnerHTML
          className="content-container"
        >
          {/* Render content safely using React components */}
          {html && <div className="content-wrapper">
            {/* If html needs to be parsed, consider using a library like html-react-parser */}
            {/* For now, displaying a placeholder or summary */}
            <p>Content available - please use a safe rendering method</p>
            {/* Consider implementing a content renderer component that safely processes HTML */}
          </div>}
        </div>
      </section>
    );
  };
  
	return (
		<Layout>
      <SEO 
        title="HR Technology Consulting | HRIS Implementation & System Optimization"
        description="Expert HR technology consulting for system selection, implementation, and optimization. Specialized in HRIS, talent acquisition systems, and workforce analytics solutions."
        keywords={[
          "HR technology consulting",
          "HRIS implementation",
          "HR system optimization",
          "talent acquisition systems",
          "workforce analytics solutions",
          "HR tech strategy",
          "HR digital transformation"
        ]}
      />
      
      {/* Hero Section - More compact */}
      <section sx={{
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        pt: [3, 4, 5],
        pb: [3, 4, 5],
        position: 'relative',
        overflow: 'hidden'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3,
          position: 'relative',
          zIndex: 2
        }}>
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
            gap: 4,
            alignItems: 'center'
          }}>
        <div>
              <h1 sx={{
                fontSize: ['2.25rem', '2.75rem', '3.25rem'],
                fontWeight: 800,
                lineHeight: 1.1,
                mb: 3,
                color: 'primary',
                letterSpacing: '-0.02em'
              }}>
                {frontmatter.title}
              </h1>
              
              <p sx={{
                fontSize: ['1.1rem', '1.25rem'],
                lineHeight: 1.4,
                mb: 3,
                color: 'text',
                opacity: 0.9
              }}>
                {frontmatter.tagline}
              </p>

              <div sx={{
                display: 'flex',
                gap: 2,
                flexWrap: 'wrap'
              }}>
                <Link to={frontmatter.cta.ctaLink} sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'primary',
                  color: 'white',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    color: 'white'
                  }
                }}>
                  {frontmatter.cta.ctaText}
                  <RiArrowRightSLine />
                </Link>

                <Link to="/contact" sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 2,
                  bg: 'white',
                  color: 'primary',
                  py: 2,
                  px: 3,
                  borderRadius: '6px',
                  fontSize: '1rem',
                  fontWeight: 600,
                  textDecoration: 'none',
                  boxShadow: '0 2px 6px rgba(0,0,0,0.05)',
                  transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
                  }
                }}>
                  Request Demo
                  <RiCalendarLine />
                </Link>
              </div>
            </div>

            <div sx={{
              position: 'relative',
              display: ['none', 'none', 'block']
            }}>
              {Image && (
                <GatsbyImage
                  image={Image}
                  alt={frontmatter.title}
            sx={{
                    borderRadius: '8px',
                    boxShadow: '0 15px 30px rgba(0,0,0,0.1)',
                    transform: 'perspective(1000px) rotateY(-5deg)',
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </section>

      {/* HR Tech Consulting Section */}
      <section sx={{
        py: [3, 4, 4],
        bg: 'white'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{ textAlign: 'center', mb: 3 }}>
            <h2 sx={{
              fontSize: ['1.75rem', '2rem'],
              fontWeight: 700,
              color: 'primary',
              mb: 2
            }}>
              HR Technology Consulting
            </h2>
            <p sx={{
              fontSize: '1.1rem',
              color: 'text',
              opacity: 0.9,
              maxWidth: '800px',
              mx: 'auto'
            }}>
              Strategic guidance and hands-on expertise to transform your HR technology ecosystem
            </p>
          </div>

          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
            gap: 3,
            mb: 4
          }}>
            {hrTechServices.map((service) => (
              <div key={service.title} sx={{
                bg: 'white',
                p: 3,
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
                }
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  {service.icon}
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  {service.title}
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5
                }}>
                  {service.description}
                </p>
              </div>
            ))}
          </div>

          <div sx={{
            mb: 5,
            maxWidth: '900px',
            mx: 'auto',
            px: 3,
            display: 'grid',
            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)'],
            gap: [3, 4]
          }}>
            <div sx={{
              p: 3,
              bg: 'white',
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                mb: 2,
                color: 'primary'
              }}>
                System Selection & Implementation
              </h3>
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                {[
                  "Vendor selection & RFP management",
                  "System requirements analysis",
                  "Implementation planning & oversight",
                  "Data migration & validation",
                  "Integration with existing systems",
                  "User acceptance testing (UAT)",
                  "Change management & training"
                ].map((item) => (
                  <li key={`implementation-${item.replace(/\s+/g, '-').toLowerCase()}`} sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 2
                  }}>
                    <span sx={{ 
                      color: 'primary',
                      mr: 2,
                      mt: '5px',
                      flexShrink: 0
                    }}>
                      <RiCheckLine />
                    </span>
                    <span sx={{
                      fontSize: '0.95rem',
                      color: 'text'
                    }}>
                      {item}
                    </span>
                  </li>
                ))}
              </ul>
            </div>

            <div sx={{
              p: 3,
              bg: 'white',
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            }}>
              <h3 sx={{
                fontSize: '1.25rem',
                fontWeight: 600,
                mb: 2,
                color: 'primary'
              }}>
                HR Tech Strategy & Optimization
              </h3>
              <ul sx={{
                listStyle: 'none',
                p: 0,
                m: 0
              }}>
                {[
                  "HR technology roadmap development",
                  "System audit & performance assessment",
                  "Process automation opportunities",
                  "User experience optimization",
                  "Workflow & approval process design",
                  "Custom reporting & analytics setup",
                  "Continuous improvement planning"
                ].map((item) => (
                  <li key={`strategy-${item.replace(/\s+/g, '-').toLowerCase()}`} sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 2
                  }}>
                    <span sx={{ 
                      color: 'primary',
                      mr: 2,
                      mt: '5px',
                      flexShrink: 0
                    }}>
                      <RiCheckLine />
                    </span>
                    <span sx={{
                      fontSize: '0.95rem',
                      color: 'text'
                    }}>
                      {item}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div sx={{
            textAlign: 'center',
            mt: 3,
            mb: 3
          }}>
            <Link to="/contact" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'primary',
              color: 'white',
              py: 2,
              px: 3,
              borderRadius: '6px',
              fontSize: '1rem',
              fontWeight: 600,
              textDecoration: 'none',
              transition: 'all 0.2s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                bg: 'primary',
                opacity: 0.9,
                color: 'white'
              }
            }}>
              Learn More About Our HR Tech Services
              <RiArrowRightSLine />
            </Link>
          </div>
        </div>
      </section>

      {/* Resources Section - Renamed from Tools Section */}
      <section sx={{
        py: [3, 4, 4],
        bg: 'primaryMuted'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{ textAlign: 'center', mb: 3 }}>
            <h2 sx={{
              fontSize: ['1.75rem', '2rem'],
              fontWeight: 700,
              color: 'primary',
              mb: 2
            }}>
              Free HR Resources
            </h2>
            <p sx={{
              fontSize: '1.1rem',
              color: 'text',
              opacity: 0.9,
              maxWidth: '600px',
              mx: 'auto'
            }}>
              Tools, guides, and policies to help you excel in human resources
            </p>
          </div>

          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)'],
            gap: 3
          }}>
            {resources.map((resource) => (
              <Link key={resource.id || resource.title} to={resource.link} sx={{
                bg: 'white',
                p: 3,
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                textDecoration: 'none',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                '&:hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
                }
              }}>
                <div sx={{
                  color: 'primary',
                  fontSize: '1.75rem',
                  mb: 2
                }}>
                  {resource.icon}
                </div>
                <h3 sx={{
                  fontSize: '1.25rem',
                  fontWeight: 600,
                  mb: 1,
                  color: 'text'
                }}>
                  {resource.title}
                </h3>
                <p sx={{
                  fontSize: '0.95rem',
                  color: 'text',
                  opacity: 0.8,
                  lineHeight: 1.5,
                  mb: 2,
                  flex: '1 1 auto'
                }}>
                  {resource.description}
                </p>
                <div sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'primary',
                  fontWeight: 600,
                  fontSize: '0.95rem',
                  mt: 'auto'
                }}>
                  Explore <RiArrowRightSLine sx={{ ml: 1 }} />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>

      {/* Latest Blog Posts - More compact */}
      <section sx={{
        py: [3, 4, 4],
        bg: 'white'
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{ textAlign: 'center', mb: 3 }}>
            <h2 sx={{
              fontSize: ['1.75rem', '2rem'],
              fontWeight: 700,
              color: 'primary',
              mb: 2
            }}>
              Latest Insights
            </h2>
            <p sx={{
              fontSize: '1.1rem',
              color: 'text',
              opacity: 0.9,
              maxWidth: '600px',
              mx: 'auto'
            }}>
              Expert advice and best practices for HR professionals
            </p>
          </div>

          <BlogListHome data={posts} />

          <div sx={{
            textAlign: 'center',
            mt: 3
          }}>
            <Link to="/blog" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              color: 'primary',
              fontSize: '1rem',
              fontWeight: 600,
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}>
              View All Articles <RiArrowRightSLine sx={{ ml: 1 }} />
            </Link>
          </div>
        </div>
      </section>

      {/* CTA Section - More compact */}
      <section sx={{
        py: [3, 4, 4],
        bg: '#F4F5F7',
        color: 'text',
        textAlign: 'center'
      }}>
        <div sx={{
          maxWidth: '800px',
          mx: 'auto',
          px: 3
        }}>
          <h2 sx={{
            fontSize: ['1.75rem', '2rem'],
            fontWeight: 700,
            mb: 2,
            color: 'primary'
          }}>
            Ready to Transform Your HR Process?
          </h2>
          <p sx={{
            fontSize: '1.1rem',
            mb: 3,
            lineHeight: 1.5,
            color: 'text'
          }}>
            Join thousands of companies using our platform to improve their HR operations
          </p>
          <div sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'center',
            flexWrap: 'wrap'
          }}>
            <Link to="/contact" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'primary',
              color: 'white',
              py: 2,
              px: 3,
              borderRadius: '6px',
              fontSize: '1rem',
              fontWeight: 600,
              textDecoration: 'none',
              transition: 'all 0.2s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                bg: 'primary',
                opacity: 0.9,
                color: 'white'
              }
            }}>
              Schedule a Demo
              <RiCalendarLine />
            </Link>
            <Link to="/contact" sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: 2,
              bg: 'transparent',
              color: 'primary',
              py: 2,
              px: 3,
              borderRadius: '6px',
              fontSize: '1rem',
              fontWeight: 600,
              textDecoration: 'none',
              border: '2px solid',
              borderColor: 'primary',
              transition: 'transform 0.2s ease, background-color 0.2s ease',
              '&:hover': {
                bg: 'transparent',
                transform: 'translateY(-2px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
              }
            }}>
              Contact Sales
              <RiMailLine />
            </Link>
          </div>
        </div>
      </section>

      {/* Social Media Links - More compact */}
      <div className="social-section" sx={{
        bg: 'white',
        pt: 2,
        pb: 2,
        textAlign: 'center',
        borderTop: '1px solid',
        borderColor: 'rgba(0,0,0,0.05)'
      }}>
        <div className="container" sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <h3 sx={{
            fontSize: '1rem',
            fontWeight: 500,
            color: 'text',
            mb: 2
          }}>Connect with PRS Consultancy</h3>
          
          <div sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 2
          }}>
            {sIcons}
          </div>
        </div>
      </div>
		</Layout>
	)
}

export default HomePage

const indexStyles = {
  socialIcons: {
    "a":{
      color: "socialIcons",
      mr: 2,
      fontSize: '1.25rem',
      ":hover":{
        color:"socialIconsHover",
      }
    }
  }
}
